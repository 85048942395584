module.exports = [{
      plugin: require('/Users/constantin/Sites/static-personal-portfolio/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"quality":100,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/constantin/Sites/static-personal-portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134442924-1","head":false,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"constantinchirila.com"},
    },{
      plugin: require('/Users/constantin/Sites/static-personal-portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
