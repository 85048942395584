// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-jsx": () => import("/Users/constantin/Sites/static-personal-portfolio/src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/constantin/Sites/static-personal-portfolio/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/constantin/Sites/static-personal-portfolio/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-work-jsx": () => import("/Users/constantin/Sites/static-personal-portfolio/src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/constantin/Sites/static-personal-portfolio/.cache/data.json")

